import * as React from 'react'
import Layout from '/src/components/layout'

const NarhetenPage = () => {

    return (
        <Layout pageTitle="Närservice">
            
            <p>
                För varje laddstation hämtas via Google Maps upp till 60 stycken verksamheter inom en radie på upp till 300 meter. Verksamheterna grupperas och du kan sedan filtrera efter i kartan. Att gå 300 meter tar cirka 3 minuter. Om ditt företag saknas vänligen kontrollera att verksamheten finns med i Google Maps.
            </p>
            <p>
                På sidan för varje station kan även lokala verksamheter ge elbilsförare ett bra erbjudande! Intresserade verksamheter kan e-posta <a href="mailto:hej@laddify.se">hej@laddify.se</a>.
            </p>

        </Layout>
    )
}

export default NarhetenPage